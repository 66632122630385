import React, { useState, useEffect } from 'react';
import { sendAccessTokenToSw } from '../utils/serviceWorkerUtils';

type Auth = {
  apiToken: string | null;
  login: (apiToken: string) => void;
  logout: () => void;
};

const AuthenticatedContext = React.createContext<Auth>({
  apiToken: null,
  login: () => {},
  logout: () => {},
});

const API_TOKEN_STORAGE_KEY = 'flairEmployeeHubApiToken';

export const AuthProvider: React.FC = (props) => {
  const [apiToken, setApiToken] = useState<string | null>(
    localStorage.getItem(API_TOKEN_STORAGE_KEY),
  );

  useEffect(() => {
    if (apiToken) {
      localStorage.setItem(API_TOKEN_STORAGE_KEY, apiToken);
      sendAccessTokenToSw(apiToken);
    } else {
      localStorage.removeItem(API_TOKEN_STORAGE_KEY);
      sendAccessTokenToSw(null);
    }
  }, [apiToken]);

  const logout = () => {
    setApiToken(null);
  };

  const login = (apiToken: string) => {
    setApiToken(apiToken);
  };

  return (
    <AuthenticatedContext.Provider
      value={{ apiToken, login, logout }}
      {...props}
    />
  );
};

export const useAuth = (): Auth => React.useContext(AuthenticatedContext);

export const useIsLoggedIn = (): boolean => {
  return !!useAuth().apiToken;
};
