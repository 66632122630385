export function sendAccessTokenToSw(accessToken: string | null) {
  postMessageToSw({
    type: 'SET_ACCESS_TOKEN',
    accessToken,
  });
}

export function postMessageToSw(message: any) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.active?.postMessage(message);
  });
}
